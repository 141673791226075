import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import QuoteForm from '../components/QuoteForm';
import  "../styles/321global.css"
import  "../styles/quotepage.css"
import { Helmet } from "react-helmet"


const QuotePage = () => {

    return (
        <div id="quote_page">
            <Helmet><title>Free Business Insurance Quote from a Qualified Insurer</title><meta name="description" content="Complete the quote request and an agent will contact you"></meta></Helmet>
            <Header />
            <div id="quote_page_heading"><h2>Complete the form below and an agent will call to confirm your business details</h2></div>
            <QuoteForm />
            <Footer />
        </div>


     );
}

export default QuotePage;
