import React, { useLayoutEffect } from 'react';
import  "../styles/quoteform.css"
import  "../styles/circles.css"
import {Helmet} from "react-helmet";
import ScriptTag from 'react-script-tag';
import Launchpad_Wordmark from '../img/launchpad-wordmark-white.svg'
//import LoadForm from '../components/LoadForm'


export default function QuoteForm (props) {
    
        // const load_form = () => {
        //     try {
        //         const form = <ScriptTag type="text/javascript" src="https://cdn.boldpenguin.com/embeddable-penguin.min.js" />;
        //         try {
        //             const form = <Helmet><script src="https://cdn.boldpenguin.com/embeddable-penguin.min.js" type="text/javascript" /></Helmet>
        //         }
        //         catch (e) {
        //             console.log(e)
        //         }
        //     }
        //     catch (e) {
        //         console.log(e)
        //     }
        // }
        // useLayoutEffect(() => {
        //     const script = document.createElement("script");
        //     script.src = "https://cdn.boldpenguin.com/embeddable-penguin.min.js";
        //     script.async = true;
        //     document.getElementById("quote_form").appendChild(script);
        // })
        
    
    return (
            <form id="quote_form">
                <div id="form_logo"><img src={Launchpad_Wordmark} /></div>
                <embeddable-penguin client_id="9UgsNpwKyVvADTxF7Q1babeycH92CrZG" custom_success_redirect="https://www.insurance321.com/success" custom_error_redirect="/submission-error" collect_email="True" />
                <Helmet><script src="https://cdn.boldpenguin.com/embeddable-penguin.min.js" type="text/javascript" /></Helmet>
                {/* <input type="text" value="Business Name"></input>
                <input type="text" value="Business Address"></input>
                <input type="text" value="First Name"></input>
                <input type="text" value="Last Name"></input>
                <input type="text" value="Email"></input>
                <input type="text" value="Callback Number"></input>
                <input type="select" value="Business Type"></input>
                <input type="button" value="Submit &nbsp; &rarr;"></input> */}
            </form>
    )
}